import { faArrowRight, faCamera, faImage } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Hidden, IconButton, Slide, TextField } from '@mui/material'
import { Close } from '@mui/icons-material'
import { useEffect, forwardRef, useState, useCallback } from 'react'
import { dateWithTime, imageToBase64 } from '../config'
import ImageDialog from './ImageDialog'
import ImageAstDialog from './ImageAstDialog'
import { isMobile } from 'react-device-detect'
import InputTextDialog from './InputTextDialog'
import { useAuth, useConnectionContext, useExecutionReportContext, useUsersContext } from '../context'
import { CameraModal } from '../modals'

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ReportDataDialog = (
  {
    open,
    handleClose,
    item,
    index,
    executionReport,
    gruposKeys,
    indexActivity,
    indexGroup,
    save,
    setChecks,
    canEdit
  }) => {
  const { userData, admin, isOperator, isSapExecutive, isShiftManager, isChiefMachinery } = useAuth()
  const { usersFilteredBySite } = useUsersContext()
  const { report } = useExecutionReportContext()
  const { isOnline } = useConnectionContext()
  const [message, setMessage] = useState('')
  const [messages, setMessages] = useState([])
  const [messagesCache, setMessagesCache] = useState([])
  const [unidad, setUnidad] = useState()
  const [openImageDialog, setOpenImageDialog] = useState(false)
  const [openImageAstDialog, setOpenImageAstDialog] = useState(false)
  const [imagePreview, setImagePreview] = useState('')
  const [openLoadingModal, setOpenLoadinModal] = useState(false)
  const [openInputTextDialog, setOpenInputTextDialog] = useState(false)
  const [astList, setAstList] = useState([])
  const [ast, setAst] = useState(false)
  const [totalMessagesWithPictures, setPicturesOfItem] = useState(0)
  const [indexKey, setIndexKey] = useState()
  const [isEdited, setIsEdited] = useState(false)
  const [canEditInternal, setCanEditInternal] = useState(false)
  const [openCamera, setOpenCamera] = useState(false);
  const [capturedImageBase64, setCapturedImageBase64] = useState(null);



  useEffect(() => {
    console.log(report)
    if ((isOperator && (userData._id === report.usersAssigned[0]._id))) {
      setCanEditInternal(true)
    }
  }, [report])

  useEffect(() => {
    gruposKeys.map((k, n) => {
      if (k.data === indexGroup) {
        setIndexKey(n)
      }
    })
    if (executionReport)
      if (executionReport.astList) {
        setAstList(executionReport.astList)
      }
    setUnidad(item.unidadData)
    if (item.messages) {
      const total = []
      item.messages.forEach(async (m, i) => {
        console.log(m)
        if (m) {
          if (m.urlBase64) {
            total.push(m)
          }
          if (i == (item.messages.length - 1)) {
            setPicturesOfItem(total.length + 1)
            setMessages(item.messages)
          }
        }
      })

      setTimeout(() => {
        document.getElementById('commits').scrollTop = document.getElementById('commits').scrollHeight
      }, 100);
    }
  }, [])

  useEffect(() => {
    if (messages.length > 0) {
      let haveMessage = false
      let haveClip = false
      const messagesCache = [...messages]
      console.log(messagesCache)
      messagesCache.forEach(async (mess, i) => {
        if (mess) {
          if (mess.urlBase64 || mess.urlImageMessage) {
            haveClip = true
          }
          if (mess.content === 'Se indica estado ejecutado sin dejar mensajes') {

          } else {
            haveMessage = true
          }
          if (i === (messages.length - 1)) {
            item.haveMessage = haveMessage
            item.haveClip = haveClip
          }
        }
      })
      setMessage('')
      setTimeout(() => {
        document.getElementById('commits').scrollTop = document.getElementById('commits').scrollHeight
      }, 50);
    }
  }, [messages])

  const changeUnidad = (value) => {
    setUnidad(value)
  }

  const inputMessage = (value) => {
    setMessage(value)
  }

  const upImage = () => {
    document.getElementById('foto').click();
  }

  const uploadImageReport = async (file) => {
    if (file) {
      let res = await imageToBase64(file)
      const text = `Imagen ${Date.now()}`
      saveMessage(res, text, null)
    }
  }
  const uploadImageReportFromImageBase64 = async (imageBase64) => {
    if (imageBase64) {
      const text = `Imagen ${Date.now()}`
      saveMessage(imageBase64, text, null)
    }
  }

  const saveMessage = async (image, messagePicture, url) => {
    if (message.length > 0 || messagePicture) {
      let totalImages = totalMessagesWithPictures
      if (image) {
        item.haveClip = true
        totalImages = totalImages + 1
        setPicturesOfItem(totalImages)
      }
      let m
      if (messagePicture) {
        m = messagePicture
      } else {
        m = message
      }
      const findMessage = messages.filter(mess => { if (messagePicture === mess.content) return mess })
      if (findMessage[0]) {
        messages.forEach((message, i) => {
          if (message.content === findMessage[0].content) {

          }
        })
      } else {
        let messageData = {
          id: Date.now(),
          namePicture: url ? `${indexKey}_Tarea_${index + 1}_Foto_${totalImages}` : null,
          content: m,
          name: `${userData.name} ${userData.lastName}`,
          user: userData._id,
          urlBase64: image,
          urlImageMessage: url
        }
        const messagesOk = []
        messages.forEach((m) => {
          if (m) {
            messagesOk.push(m)
          }
        })
        const messagesCache = [...messagesOk]
        messagesCache.push(messageData)
        setMessages(messagesCache)
      }

    }
  }

  const saveItem = (index, state, item) => {
    const messagesOk = []
    messages.forEach((m) => {
      if (m) {
        messagesOk.push(m)
      }
    })
    item.messages = [...messagesOk]
    setIsEdited(false)
    let stateToSend = false
    if (item.isRequerido) {
      item.unidadData = unidad
      if (item.unidadData) {
        save(index, state, item)
        executionReport.offLineGuard = Date.now()
        stateToSend = true
      } else {
        if (item.messages.length > 0) {
          save(index, state, item)
          executionReport.offLineGuard = Date.now()
          stateToSend = true
        } else {
          alert('Debe dejar al menos un comentario')
        }
        alert('Debe indicar una cantidad como se solicita.')
      }
    } else {
      stateToSend = true
      save(index, state, item)
      executionReport.offLineGuard = Date.now()
      stateToSend = true
    }
    console.log(item, stateToSend)
    if (stateToSend === true) {
      handleClose()
    }
  }

  const openImage = (image) => {
    setImagePreview(image)
    setOpenImageDialog(true)
  }

  const openCameraToAst = () => {
    setOpenCamera(true)
  }

  const closeCamera = () => {
    setOpenCamera(false)
  }

  const handleCloseImage = () => {
    setOpenImageDialog(false)
  }

  const handleCloseText = () => {
    setOpenInputTextDialog(false)
  }

  const handleCloseAstImage = () => {
    setOpenImageAstDialog(false)
  }

  const disable = () => {
    document.onkeydown = (e) => {
      return false
    }
  }

  const deleteMessage = (index) => {
    if (confirm('¿Confirma borrar el mensaje?')) {
      setIsEdited(true)
      const messagesNew = []
      messages.forEach((m, i) => {
        if (index === i) {
          null
        } else {
          messagesNew.push(m)
        }
        if (i === (messages.length - 1)) {
          setMessages(messagesNew)
          item.messages = messagesNew
          if (item.messages.length === 0) {
            item.haveMessage = false
            item.haveClip = false
          } else {
            let haveClip = false
            let haveMessage = false
            item.messages.forEach((mess, index) => {
              console.log(mess)
              if (mess.content === 'Se indica estado ejecutado sin dejar mensajes') {

              } else {
                haveMessage = true
              }
              if (mess.urlBase64 || mess.urlImageMessage) {
                haveClip = true
              }
              console.log('Clip', haveClip)
              if (index === (item.messages.length - 1)) {
                item.haveClip = haveClip
                item.haveMessage = haveMessage
              }
            })
          }
        }
      })
    }
  }

  const close = () => {
    console.log(JSON.stringify(messages), JSON.stringify(item.messages ? item.messages : []))
    if (JSON.stringify(messages) === JSON.stringify(item.messages ? item.messages : [])) {
      handleClose()
    } else {
      if (window.confirm('Si cierra la ventana sin guardar los nuevos mensajes, éstos se borrarán. Click en cancelar para volver atrás')) {
        handleClose()
        if (!item.messages || (item.messages.length === 0)) {
          item.haveMessage = false
          item.haveClip = false
        }
      }
    }
  }

  const handleOpenCamera = (e) => {
    e.preventDefault();
    setOpenCamera(true);
  }
  const handleCloseCamera = (e) => {
    if (e) e.preventDefault();
    setOpenCamera(false);
  }

  const handleImageCapture = (imageBase64) => {
    setCapturedImageBase64(imageBase64);
    uploadImageReportFromImageBase64(imageBase64)
    console.log(imageBase64);
  };


  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      disableEscapeKeyDown
      aria-describedby="alert-dialog-slide-description"
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "500px",
          },
        }
      }}
    >
      <div style={{ position: 'absolute', right: 50, top: 10 }}>
        <IconButton onClick={() => close()} style={{ position: 'fixed' }}><Close /></IconButton>
      </div>
      <DialogTitle style={{ marginTop: 10 }}>{item.taskdesc}</DialogTitle>
      <DialogContent>
        <div style={{ maxHeight: 100, overflowY: 'auto' }}>
          <DialogContentText id="alert-dialog-slide-description" style={{ whiteSpace: 'pre-line' }}>
            {item.obs01} <br />
          </DialogContentText>
        </div>
        {(item.unidad !== '*') &&

          ((
            item.unidad === '%' ||
            item.unidad === '°C' ||
            item.unidad === 'CM' ||
            item.unidad === 'H' ||
            item.unidad === 'KM' ||
            item.unidad === 'KPA' ||
            item.unidad === 'M' ||
            item.unidad === 'MM' ||
            item.unidad === 'N' ||
            item.unidad === 'PSI' ||
            item.unidad === 'RPM' ||
            item.unidad === 'T' ||
            item.unidad === 'V'
          ) ?
            <Grid container>
              <Grid item sm={3}>
                <div style={{ marginLeft: 24, marginBottom: 16 }}>
                  <h3>Ingrese parámetro</h3>
                  <TextField
                    id="standard-basic"
                    label={item.unidad}
                    variant="standard"
                    type='number'
                    value={unidad}
                    onChange={(e) => { changeUnidad(e.target.value) }}
                  />
                </div>
              </Grid>
              <Grid item xl={4} lg={4} md={4} sm={4}>
                <Grid container>
                  <Grid item sm={12}>
                    <div>
                      {/* <p>Param. a Medir: <br /> {item.cantidad} {item.unidad}</p> */}
                      <p>{item.partnumberUtl}</p>
                    </div>
                  </Grid>
                  <Grid item xl={6} lg={6} md={6} sm={6}>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            :
            <Grid container>
              <Grid item sm={4}>
                <div style={{ marginLeft: 24, marginBottom: 16, padding: '0px 10px 0px 0px' }}>
                  <h3>Ingrese valor</h3>
                  <TextField
                    id="standard-basic"
                    label={item.unidad}
                    variant="standard"
                    type='number'
                    value={unidad}
                    onChange={(e) => { changeUnidad(e.target.value) }}
                  />
                </div>
              </Grid>
              <Grid item sm={6}>
                <Grid container>
                  <Grid item xl={6} lg={6} md={6} sm={6}>
                    <div style={{ padding: '0px 10px 0px 10px' }}>
                      <p>Cant. Utilizar: <br /> {item.cantidad} {item.unidad}</p>
                      <p>{item.partnumberUtl}</p>
                    </div>
                  </Grid>
                  <Grid item xl={6} lg={6} md={6} sm={6}>
                    <div style={{ padding: '0px 10px 0px 10px' }}>
                      <p>Tipo Rpto: <br /> {item.idtypeutlPartnumber}</p>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
        <div style={{ marginBottom: 20 }}>
          <div style={{ width: '100%', height: 250, borderWidth: 2, borderStyle: 'solid', borderColor: '#ccc', borderRadius: 8, position: 'relative' }}>
            <div id='commits' style={{ width: '100%', height: 190, overflowY: 'auto', scrollBehavior: 'smooth'/* borderWidth: 2, borderStyle: 'solid', borderColor: '#ccc', borderRadius: 20 */ }}>
              {
                (messages.length === 0)
                &&
                <p>No hay mensajes</p>
              }
              {
                messages.map((message, index) => {
                  if (message) {
                    return (
                      <div key={index} style={
                        {
                          position: 'relative',
                          color: '#555',
                          padding: 5,
                          margin: 5,
                          borderRadius: 8,
                          borderWidth: 2,
                          borderStyle: 'solid',
                          borderColor: '#dddddd',
                          backgroundColor: '#F5F5F5'
                        }
                      }>
                        {
                          (((userData._id === message.user) || admin)) &&
                          <IconButton onClick={() => { deleteMessage(index) }} style={{ position: 'absolute', top: 10, right: 10 }}><Close style={{ fontSize: 14, color: 'black' }} /></IconButton>
                        }
                        <div style={{ width: '100%' }}>
                          <p style={{ marginBottom: 0, fontSize: 10 }}><strong>{message.name}</strong></p>
                        </div>
                        {(message.urlBase64 || message.urlImageMessage) && <img src={message.urlBase64 ? message.urlBase64 : message.urlImageMessage} height={70} onClick={() => openImage((message.urlBase64.length > 0) ? message.urlBase64 : message.urlImageMessage)} />}
                        <div style={{ width: '100%' }}>
                          <p style={{ margin: 0, whiteSpace: 'pre-line' }}>{message.content}</p>
                        </div>
                        <div style={{ width: '100%', textAlign: 'right' }}>
                          <p style={{ margin: 0, fontSize: 10, right: 5, bottom: 0 }}>{dateWithTime(message.id)}</p>
                        </div>
                      </div>
                    )
                  }
                })
              }
            </div>
            <div style={{ width: '100%', height: 50, borderTopWidth: 2, borderTopStyle: 'solid', borderTopColor: '#ccc', position: 'absolute', bottom: 0, left: 0, paddingLeft: 10, paddingRight: 10 }}>
              <form>
                {(canEdit || (isOperator && (userData._id === report.usersAssigned[0]._id))) && <div style={{ width: '70%', float: 'left' }}>
                  {
                    <Hidden mdDown>
                      <textarea disabled={!canEditInternal} value={message} placeholder='Ingrese comentarios' type="text" style={{ width: '100%', borderColor: 'transparent', resize: 'none' }} onChange={(e) => { inputMessage(e.target.value) }} />
                    </Hidden>
                  }
                  {
                    <Hidden mdUp>
                      <textarea disabled={!canEditInternal} id="mobileTextArea" value={message} placeholder='Ingrese comentarios' type="text" style={{ width: '100%', borderColor: 'transparent', resize: 'none' }} onChange={(e) => { console.log(e.target.value) }} onClick={() => { setOpenInputTextDialog(true); document.getElementById('mobileTextArea').blur() }} />
                    </Hidden>
                  }
                </div>}
                <Grid container style={{ width: '30%', float: 'right', textAlign: 'right' }}>

                  {(canEdit || (isOperator && (userData._id === report.usersAssigned[0]._id))) &&
                    <IconButton onClick={handleOpenCamera}>
                      <FontAwesomeIcon icon={faCamera} />
                    </IconButton>
                  }

                  {(canEdit || (isOperator && (userData._id === report.usersAssigned[0]._id))) && <IconButton onClick={() => { upImage(); setAst(false) }}>
                    <FontAwesomeIcon icon={faImage} />
                  </IconButton>}
                  <Hidden mdDown>
                    {(canEdit || (isOperator && (userData._id === report.usersAssigned[0]._id))) && <IconButton onClick={() => saveMessage()}>
                      <FontAwesomeIcon icon={faArrowRight} />
                    </IconButton>}
                  </Hidden>

                  <input autoComplete="off" type="file" id="foto" accept="image/x-png,image/jpeg" onChange={(e) => { uploadImageReport(e.target.files[0]) }} hidden />
                  {openCamera && (
                    <CameraModal
                      open={openCamera}
                      handleClose={handleCloseCamera}
                      onImageCapture={handleImageCapture}
                    />
                  )}
                </Grid>
              </form>
            </div>
          </div>
        </div>

        {((isOperator && (userData._id === report.usersAssigned[0]._id))) &&
          <Grid container>
            <Grid item xs={12} md={6} style={{ textAlign: 'center', marginBottom: 10 }}>
              <Button style={{ backgroundColor: '#D5CC41', color: '#fff' }} onClick={() => { saveItem(index, false, item) }}>Guardar sin ejecutar</Button>
            </Grid>
            <Grid item xs={12} md={6} style={{ textAlign: 'center', marginBottom: 10 }}>
              <Button style={{ backgroundColor: '#9ACF26', color: '#fff' }} onClick={() => { saveItem(index, true, item) }}>Guardar ejecutado</Button>
            </Grid>
          </Grid>
        }
      </DialogContent>

      {
        openImageDialog && <ImageDialog open={openImageDialog} image={imagePreview} handleClose={handleCloseImage} />
      }
      {
        openImageAstDialog && <ImageAstDialog open={openImageAstDialog} images={astList} handleClose={handleCloseAstImage} setIsEdited={setIsEdited} />
      }
      {
        openInputTextDialog && <InputTextDialog open={openInputTextDialog} handleClose={handleCloseText} saveMessage={saveMessage} message={message} inputMessage={inputMessage} />
      }
      {
        openLoadingModal && <LoadingModal open={openLoadingModal} withProgress={false} loadingData={'Cargando imágen'} />
      }
   
    </Dialog>
  )
}

export default ReportDataDialog