import React, { createContext, useContext, useEffect, useState } from 'react'
import { useAuth } from './Auth.context'
import { executionReportsRoutes, patternsRoutes, reportsRoutes } from '../routes'
import { executionReportsDatabase, reportsDatabase } from '../indexedDB'
import { useConnectionContext } from './Connection.context'
import { SocketConnection } from '../connections'
/* import { useNotificationsContext } from './Notifications.context' */
import { useNavigate } from 'react-router-dom'
import addNotification from 'react-push-notification'
import logoNotification from '../assets/logo_icv_notification_push.png'
import { useLoadingContext } from './Loading.context'
import { LinearProgress } from '@mui/material'
import axios from 'axios'
import { useUsersContext } from './Users.context'
import { findMyAssignations, getNumerosReportes } from '../routes/reports.routes'
import { useSitesContext } from './Sites.context'

export const ReportsContext = createContext()

export const ReportsProvider = (props) => {
    const {isOnline} = useConnectionContext()
    const {admin, isSapExecutive, isShiftManager, isChiefMachinery, site, isOperator, isAuthenticated, userData} = useAuth()
    const [reports, setReports] = useState([])
    const {siteSelected, setSiteSelected} = useSitesContext()
    const [reportsCache, setReportsCache] = useState([])
    const [reportsResume, setReportsResume] = useState([])
    const [assignments, setAssignments] = useState([])
    const [listSelected, setListSelected] = useState([])
    const [listSelectedCache, setListSelectedCache] = useState([])
    const [pautas, setPautas] = useState([])
    const [pautasCache, setPautasCache] = useState([])
    const [loading, setLoading] = useState(false)
    const [priorityAssignments, setPriorityAssignments] = useState([])
    const [normalAssignments, setnormalAssignments] = useState([])
    const [message, setMessage] = useState('Descargando reportes')
    const [statusReports, setStatusReports] = useState(false)
    const [revision, setRevision] = useState(true)
    const [reportSelected, setReportSelected] = useState()
    const [itemProgress, setItemProgress] = useState()
    const [reportsProgress, setReportsProgress] = useState()
    const [finishReportsProgress, setFinishReportsProgress] = useState(true)
    const [serverUrl, setServerUrl] = useState()
    const [waitReports, setWaitReports] = useState(false)

    /* ACTUALIZACIÓN POST CAÍDA DE BBDD */
    const [numerosEnReportes, setNumerosEnReportes] = useState()
    const [reportsToShow, setReportsToShow] = useState([])
    const [valueFilter, setValueFilter] = useState('')
    const [tipoReporteSeleccionado, setTipoReporteSeleccionado] = useState('')
    const [reportesCalendario, setReportesCalendario] = useState([])
    const navigate = useNavigate()

    useEffect(() => {
        if (process.env.NODE_ENV === 'production') {
            setServerUrl('https://mantencion.icv.cl')
        } else {
            setServerUrl('http://localhost:5001')
        }
    }, [])

    useEffect(() => {
        setReports(reportsCache)
    }, [reportsCache])

    useEffect(() => {
        if(reportsProgress) {
            console.log(reportsProgress)
            setWaitReports(false)
            if (reportsProgress.loaded < reportsProgress.total) {
                setFinishReportsProgress(false)
            } else {
                setTimeout(() => {
                    setFinishReportsProgress(true)
                }, 1000);
            }
        }
    }, [reportsProgress])

    useEffect(() => {
        if (isOnline && userData && isAuthenticated) {
            if (isOperator && !admin) {
                saveReportsFromLocalDatabase().then(() => {
                    stateReports()
                })
            }
        }
    },[userData, isOnline, isAuthenticated, isOperator, admin, isShiftManager, isChiefMachinery, isSapExecutive])

    const saveReportsFromLocalDatabase = async () => {
        return new Promise(async resolve => {
            const {database} = await reportsDatabase.initDbReports()
            const reportsCache = await reportsDatabase.consultar(database)
            reportsCache.forEach((report, i) => {
                if (i === reportsCache.length - 1) {
                    resolve({
                        state: true
                    })
                }
            })
        })
    }

    const reportsData = (data) => {
        getReports()
    }

    const stateReports = async () => {
        SocketConnection.listenReports(userData, reportsData)
        SocketConnection.listenNotifocations(userData, getData)
    }

    const getData = (data) => {
        addNotification({
            icon: logoNotification,
            title: data.title,
            subtitle: data.subtitle,
            message: data.message,
            theme: 'red',
            native: true
        })
        if (data.report) {
            getReports()
        }
    }

    useEffect(() => {
        if(isAuthenticated) {
            if (reports.length > 0) {
                if (reportsCache.length === 0) {
                    setReportsCache(reports)
                }
                const reportsResumeCache = []
                reports.forEach((report) => {
                    if (report) {
                        reportsResumeCache.push({
                            _id: report._id,
                            idIndex: report.idIndex,
                            dateInit: report.dateInit,
                            datePrev: report.datePrev,
                            isAutomatic: report.isAutomatic,
                            guide: report.guide,
                            equ: report.maquina ? report.maquina.equ : null
                        })
                    }
                })
                setReportsResume(reportsResumeCache)
                setAssignments(reports)
                filterReports(reports)
            } else {
                if (!siteSelected) {
                    setMessage('Descargando recursos')
                    setAssignments([])
                    setReportsResume([])
                }
            }
        }
    }, [reports, isAuthenticated])

/*     useEffect(() => {
        if (pautas.length > 0 && isAuthenticated) {
            setMessage('Guardando pautas')
            savePautas()
        }
    }, [pautas, isAuthenticated]) */

    const elevarReporte = async (reportDataToSend, reportData) => {
        const reportsCache = [...reports]
        const response = await reportsRoutes.editReportById(reportDataToSend)
        const reportUpdated = response.data
        const reportIndex = reportsCache.find((report, index) => {
            if (report._id === reportUpdated._id) return index
        })
        reportsCache[reportIndex] = reportUpdated
        setReportsCache(reportsCache)
        alert('reporte elevado')
        setLoading(false)
    }

    const saveReport = async (reportData) => {
        setMessage('Guardando reportes')
        const reportsCache = [...reports]
        const response = await reportsRoutes.editReportById(reportData)
        const reportUpdated = response.data
        const reportIndex = reportsCache.find((report, index) => {
            if (report._id === reportUpdated._id) return index
        })
        reportsCache[reportIndex] = reportUpdated
        setReportsCache(reportsCache)
        alert('reporte asignado')
        setLoading(false)
    }

    const createReport = async (reportData, setLoading) => {
        setLoading(true)
        const reportsCache = [...reports]
        const response = await reportsRoutes.createReport(reportData)
        const reportUpdated = response.data
        reportsCache.push(reportUpdated)
        setReportsCache(reportsCache)
        alert(`Reporte ${reportUpdated.idIndex}, para máquina modelo ${reportUpdated.machine} creado satisfactoriamente.`)
        setLoading(false)
        navigate('/reports', {replace: true})
    }

    const saveReportAsignation = async (reportData) => {
        setMessage('Guardando reportes')
        const reportsCache = [...reports]
        const response = await reportsRoutes.editReportById(reportData)
        const reportUpdated = response.data
        const reportIndex = reportsCache.find((report, index) => {
            if (report._id === reportUpdated._id) return index
        })
        reportsCache[reportIndex] = reportUpdated
        setReports(reportsCache)
    }

    const saveReportToData = async (reportData) => {
        setMessage('Guardando reportes')
        const reportsCache = [...reports]
        if(isOnline) {
            const response = await reportsRoutes.editReport(reportData)
            const reportUpdated = response.data
            const reportIndex = reportsCache.find((report, index) => {
                if (report._id === reportUpdated._id) return index
            })
            reportsCache[reportIndex] = reportUpdated
            setReportsCache(reportsCache)
        }
    }

    const getReportsFromDatabase = async () => {
        setMessage('Descargando recursos')
        const {database} = await reportsDatabase.initDbReports()
        const response = await reportsDatabase.consultar(database)
        if (response.length > 0) {
            /* setReports(response) */
            setReportsCache(reportsCache)
        } else {
            setMessage('')
        }
    }

    useEffect(() => {
        setMessage('Guardando asignaciones')
        if (assignments.length > 0) {
            if (isShiftManager) {
                const priorityAssignmentsCache = []
                const assignmentsCache = []
                assignments.forEach((assignment, i) => {
                    if(assignment.guide === 'Pauta de Inspección') {
                        assignment._guide = 'PI' 
                    }else{
                        assignment._guide = assignment.guide
                    }
        
                    if ((!assignment.level || assignment.level === 0) && (assignment.usersAssigned[0] && assignment.usersAssigned[0]._id === userData._id)) {
                        assignment.isPrioritary = true
                        priorityAssignmentsCache.push(assignment)
                    }
                    if (assignment.level === 1) {
                        assignment.isPrioritary = true
                        priorityAssignmentsCache.push(assignment)
                    } else {
                        assignment.isPrioritary = false
                        assignmentsCache.push(assignment)
                    }
                })
                setPriorityAssignments(priorityAssignmentsCache.filter((report) => {if (report.level === 4) {return null} else {return report}}))
                setnormalAssignments(assignmentsCache)
            } else if (isChiefMachinery) {
                const priorityAssignmentsCache = []
                const assignmentsCache = []
                assignments.forEach((assignment, i) => {
                    if(assignment.guide === 'Pauta de Inspección') {
                        assignment._guide = 'PI' 
                    }else{
                        assignment._guide = assignment.guide
                    }
                    if (assignment.level === 2) {
                        assignment.isPrioritary = true
                        priorityAssignmentsCache.push(assignment)
                    } else {
                        assignment.isPrioritary = false
                        assignmentsCache.push(assignment)
                    }
                })
                setPriorityAssignments(priorityAssignmentsCache.filter((report) => {if (report.level === 4) {return null} else {return report}}))
                setnormalAssignments(assignmentsCache.filter((report) => {if (report.level === 4) {return null} else {return report}}))
            } else if (isOperator || admin || isSapExecutive) {
                assignments.forEach((assignment, i) => {
                    if(assignment.guide === 'Pauta de Inspección') {
                        assignment._guide = 'PI' 
                    }else{
                        assignment._guide = assignment.guide
                    }
                })
                const newAssignment = assignments.filter((report) => {if (report.level === 4) {return null} else {return report}})
                setPriorityAssignments(newAssignment)
            }
        } else {
            setPriorityAssignments([])
            setnormalAssignments([])
        }
        saveReportsToIndexedDb()
    }, [assignments, pautas])

    useEffect(() => {
        if (isAuthenticated && admin) {
            setSiteSelected('none')
        } else if (site) {
            setSiteSelected(site._id)
        }
        setMessage('Descargando reportes')
        if ((admin || isOperator || isSapExecutive || isShiftManager || isChiefMachinery)&&userData) {
            getReports()
        }
    }, [admin, isOperator, isSapExecutive, isShiftManager, isChiefMachinery, isOnline, isAuthenticated, site, userData])

    const downloadPautas = async () => {
        const respuestaPautas = await patternsRoutes.getPatternsFields()
        console.log(respuestaPautas.pautas)
        setPautas(respuestaPautas.pautas)
        setPautasCache(respuestaPautas.pautas)
    }

    const guardarContadorEnReporte = async (reporte) => {
        const response = await reportsRoutes.editReportCounter(reporte._id, reporte.ejecuciones)
        console.info(response)
    }

    const saveReportsToIndexedDb = async () => {
        setMessage('Actualizando OTs')
        const {database} = await reportsDatabase.initDbReports()
        assignments.forEach(async (report, i) => {
            await reportsDatabase.actualizar(report, database)
        })
        const elementsToremoved = await reportsDatabase.consultar(database)
        elementsToremoved.forEach(async (el) => {
            const filtered = assignments.filter(report => {
                if (el.idIndex === report.idIndex) {
                    return report
                }
            })
            if (filtered.length === 0) {
                await reportsDatabase.eliminar(el.idIndex, database)
            }
        })
        setMessage('')
        setLoading(false)
    }

    const getReportsFromIndexedDb = async () => {
        const {database} = await reportsDatabase.initDbReports()
        const reportsResponse = await reportsDatabase.consultar(database)
        setReportsCache(reportsResponse)
    }

    

    useEffect(() => {
        if (siteSelected) {
            getReports()
        }
    },[siteSelected])

    /* const buscarReportesFiltrados = async () => {
        setReports([])
        setStatusReports(true)
        if (isOnline) {
            if (admin) {
                console.log('obra:', siteSelected)
                if (siteSelected) {
                    const respuesta = await reportsRoutes.reportesPaginadosFiltrados(desde, maximo, siteSelected === 'none' ? 'all' : siteSelected, tipoReporteSeleccionado)
                    setReportsToShow(respuesta.reportes)
                }
                setStatusReports(false)
                setReportsToShow([])
                downloadPautas()
            } else {
                if (isOperator && (isSapExecutive || isShiftManager || isChiefMachinery)) {
                    setStatusReports(false)
                    setReportsToShow([])
                    const responseReports = await findMyAssignations(userData._id, siteSelected, setReportsProgress, serverUrl)
                    const ejecuciones = await executionReportsDatabase.consultarTodas()
                    const reportsFinal = await Promise.all(responseReports.map((report, i) => {
                        const ejecucion = ejecuciones.filter(e => (e.reportId === report._id))
                        if (ejecucion && ejecucion[0]) {
                            report.tieneEjecucionEnDispositivo = ejecucion[0]
                        }
                        if (report.origen) {
                            if (report.usersAssigned && report.usersAssigned.length > 0) {
                                report.origen = false
                            }
                        }
                        return report
                    }))
                    setReportsCache(reportsFinal)
                    setStatusReports(false)
                } else if (!isOperator && (isSapExecutive || isShiftManager || isChiefMachinery)) {
                    const response = await getNumerosReportes(siteSelected)
                    console.log(response)
                    setNumerosEnReportes(response)
                    setStatusReports(false)
                    setReportsToShow([])
                    setStatusReports(false)
                }
            }
        } else {
            getReportsFromIndexedDb()
            setStatusReports(false)
        }

    } */

    const getReports = async () => {
        setReports([])
        setStatusReports(true)
        if (isOnline) {
            if (admin) {
                const response = await getNumerosReportes(siteSelected === 'none' ? 'all' : siteSelected)
                console.log(response)
                setNumerosEnReportes(response)
                setStatusReports(false)
                setReportsToShow([])
                downloadPautas()
            } else {
                if (isOperator && (!isSapExecutive && !isShiftManager && !isChiefMachinery)) {
                    const response = await findMyAssignations(userData._id, siteSelected, setReportsProgress, serverUrl)
                    setReportsCache(response)
                    setStatusReports(false)
                } else if (isOperator && (isSapExecutive || isShiftManager || isChiefMachinery)) {
                    const response = await getNumerosReportes(siteSelected)
                    console.log(response)
                    setNumerosEnReportes(response)
                    setStatusReports(false)
                    setReportsToShow([])
                    const responseReports = await findMyAssignations(userData._id, siteSelected, setReportsProgress, serverUrl)
                    const ejecuciones = await executionReportsDatabase.consultarTodas()
                    const reportsFinal = await Promise.all(responseReports.map((report, i) => {
                        const ejecucion = ejecuciones.filter(e => (e.reportId === report._id))
                        if (ejecucion && ejecucion[0]) {
                            report.tieneEjecucionEnDispositivo = ejecucion[0]
                        }
                        if (report.origen) {
                            if (report.usersAssigned && report.usersAssigned.length > 0) {
                                report.origen = false
                            }
                        }
                        return report
                    }))
                    setReportsCache(reportsFinal)
                    setStatusReports(false)
                } else if (!isOperator && (isSapExecutive || isShiftManager || isChiefMachinery)) {
                    const response = await getNumerosReportes(siteSelected)
                    console.log(response)
                    setNumerosEnReportes(response)
                    setStatusReports(false)
                    setReportsToShow([])
                    setStatusReports(false)
                }
            }
        } else {
            getReportsFromIndexedDb()
            setStatusReports(false)
        }
    }

    const filterReports = async (reports) => {
            const {database} = await reportsDatabase.initDbReports()
            const reportsDB = await reportsDatabase.consultar(database)
            const reportsToDelete = []
            reportsDB.forEach((reporte) => {
                const reporteFiltrado = reports.filter(r => {
                    if (r._id === reporte._id) {
                        return r
                    }
                })
                if (reporteFiltrado.length === 0) {
                    reportsToDelete.push(reporte)
                }
            })
            reportsToDelete.forEach(async (r) => {
                await reportsDatabase.eliminar(r.idIndex, database)
            })
    }

    const findDuplicates = (array) => {
        const duplicates = [];
        
        for (let i = 0; i < array.length; i++) {
          for (let j = i + 1; j < array.length; j++) {
            if (array[i]._id === array[j]._id && !duplicates.includes(array[i]._id)) {
              duplicates.push(array[i]);
            }
          }
        }
        
        return duplicates;
      }

    const getReportsOffline = () => {
        getReportsFromIndexedDb()
    }

    const borrarListaReportes = async (listaReportes) => {
        const response = await axios.post('/reports/borrarListaReportes', {listaReportes})
        getReports()
        return response.data
    }

    const reportesSeleccionados = async (estado, desde, maximo, tipoReporte) => {
        /* setReportsToShow([]) */
        console.log('obra:', siteSelected)
        if (siteSelected) {
            const respuesta = await reportsRoutes.reportesPaginados(estado, desde, maximo, siteSelected === 'none' ? 'all' : siteSelected, tipoReporte)
            setReportsToShow(respuesta.reportes)
        }
    }

    const filtroReporte = async (estado, desde, maximo, tipoReporte, value) => {
        if (siteSelected) {
            const respuesta = await reportsRoutes.reportesPaginadosFiltrados(estado, desde, maximo, siteSelected === 'none' ? 'all' : siteSelected, tipoReporte, value)
            setReportsToShow(respuesta.reportes)
            return {
                numeroItems: respuesta.contador
            }
        } else {
            return {
                numeroItems: null
            }
        }
    }

 
    const provider = {
        reports,
        setReports,
        assignments,
        listSelected,
        listSelectedCache,
        setListSelected,
        setListSelectedCache,
        loading,
        pautas,
        priorityAssignments,
        normalAssignments,
        saveReport,
        elevarReporte,
        saveReportToData,
        getReports,
        getReportsOffline,
        message,
        setMessage,
        statusReports,
        setStatusReports,
        createReport,
        reportsResume,
        setSiteSelected,
        siteSelected,
        guardarContadorEnReporte,
        setReportSelected,
        reportSelected,
        itemProgress,
        setItemProgress,
        borrarListaReportes,
        /* downloadPautas, */
        numerosEnReportes,
        reportesSeleccionados,
        reportsToShow,
        /* buscarReportesFiltrados, */
        valueFilter,
        setValueFilter,
        tipoReporteSeleccionado,
        setTipoReporteSeleccionado,
        filtroReporte
    }

    return (
        <>
            <ReportsContext.Provider value={provider} {...props} />
            {
                (waitReports && finishReportsProgress) && <div style={{
                    height: 100,
                    width: 300,
                    paddingTop: 10,
                    position: 'absolute', right: 50, bottom: 50, backgroundColor: 'white', border: '1px solid #ccc', borderRadius: 8, padding: 10, textAlign: 'center'}}>
                    <p style={{marginTop: 25}}>
                        Esperando reportes
                    </p>
                </div>
            }
            {!finishReportsProgress && <div style={{
                height: 100,
                width: 300,
                position: 'absolute', right: 50, bottom: 50, backgroundColor: 'white', border: '1px solid #ccc', borderRadius: 8, padding: 10}}>
                <LinearProgress
                    color='primary'
                    style={{margin: '10px 0px'}}
                    variant={'determinate'}
                    value={
                        (reportsProgress.loaded*100) / reportsProgress.total
                    }
                />
                <p>
                    Descargando reportes
                </p>
            </div>}
        </>
    )
}

export const useReportsContext = () => useContext(ReportsContext)