import {useState, useEffect} from 'react'
import {Grid, Toolbar, IconButton, TablePagination, Typography, Box, Card, ListItemButton, ListItemText, Chip, List, Collapse, ListItemIcon, Pagination, TextField} from '@mui/material';
import { ArrowBackIos, Close, ExpandLess, ExpandMore, Search, StarBorder } from '@mui/icons-material';
import { Mantenciones, Inspecciones } from './ReportsListLeft';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faClipboardList, faCalendar, faDownload, faPlus, faInfoCircle, faMapMarker, faChartBar, faTrash, faFilter, faLeaf } from '@fortawesome/free-solid-svg-icons';
import './reports.css'
import { ReportsList } from '../../containers';
import { useNavigate } from 'react-router-dom';
import { useAuth, useReportsContext, useSitesContext } from '../../context';
import * as XLSX from 'xlsx'
import { ReportsResumeDialog } from '../../dialogs';
import SeleccionarObraDialog from '../../dialogs/SeleccionarObraDialog';
import ReportsKPIDialog from '../../dialogs/ReportsKPIDialog';
import { descargarDataCompilada } from '../../routes/databaseConnection.routes';

const ReportsPage = () => {

    const {sites} = useSitesContext()
    const {admin, isSapExecutive, isShiftManager} = useAuth()
    const {numerosEnReportes,
        reports, 
        setListSelected, 
        listSelected, 
        listSelectedCache, 
        getReports, 
        statusReports, 
        pautas, 
        siteSelected,
        borrarListaReportes,
        reportesSeleccionados,
        reportsToShow,
        valueFilter,
        setValueFilter,
        tipoReporteSeleccionado,
        setTipoReporteSeleccionado,
        filtroReporte
    } = useReportsContext()
    const [ inspecciones, setInspecciones ] = useState([]);
    const [ mantenciones, setMantenciones ] = useState([]);
    const [ hableCreateReport, setHableCreateReport ] = useState(false);
    const [ vista, setVista ] = useState(true);
    const [ reportesPorPagina, setReportesPorPagina ] = useState(10)
    const [ totalPaginas, setTotalPaginas ] = useState(0)
    const [ pagina, setPagina]  = useState(1);
    const [ numeroInicialPagina, setNumeroInicialPagina] = useState(1)
    const [ listToShow, setListToShow ] = useState([])
    const [ listToShowCache, setListToShowCache ] = useState([])
    const [ totalItems, setTotalItems ] = useState(0)
    const [allItemsSelected, setAllItemsSelected] = useState(false)
    const [site, setSite] = useState('nada')
    const navigate = useNavigate()
    const [canOpenNewReport, setCanOpenNewReport] = useState(false)
    const [openReportSumary, setOpenReportSumary] = useState(false)
    const [openSeleccionarObra, setOpnSeleccionarObra] = useState(false)
    const [opnKPI, setOpnKPI] = useState(false)
    const [siteObject, setSiteObject] = useState()
    const [tipo, setTipo] = useState()

    const [filtrarReporte, setFiltrarReporte] = useState(false)
    
    useEffect(() => {
        if (sites.length > 0 && siteSelected) {
            const siteFiltered = sites.filter(site => {if (site.idobra === siteSelected) return site})[0]
            setSiteObject(siteFiltered)
        }
    },[siteSelected])

    useEffect(() => {
        if (pautas.length === 0) {
            setCanOpenNewReport(false)
        } else {
            setCanOpenNewReport(true)
        }
    }, [pautas])

    useEffect(() => {
        if (admin || isSapExecutive) {
            setHableCreateReport(true)
        }
    }, [admin, isSapExecutive])

    useEffect(() => {
        if (reportsToShow.length > 0) {
            setInspecciones(Inspecciones)
            setMantenciones(Mantenciones)
            setListToShow(reportsToShow.map(report => {
                return {...report, estado: levelToState(report.level, report.usersAssigned, report.state), checked: false}
            }))
            setListToShowCache(reportsToShow.map(report => {
                return {...report, estado: levelToState(report.level, report.usersAssigned, report.state), checked: false}
            }))
        } else {
            setInspecciones(Inspecciones)
            setMantenciones(Mantenciones)
            setListToShow([])
            setListToShowCache([])
            setVista(true)
        }
    }, [reportsToShow])

    useEffect(() => {
        if (numerosEnReportes) {
            console.log(numerosEnReportes)
            const inspeccionesTable = [...Inspecciones]
            inspeccionesTable[0].number = numerosEnReportes.inspecciones.completadas
            inspeccionesTable[1].number = numerosEnReportes.inspecciones.porCerrar
            inspeccionesTable[2].number = numerosEnReportes.inspecciones.enProceso
            inspeccionesTable[3].number = numerosEnReportes.inspecciones.asignar
            inspeccionesTable[4].number = numerosEnReportes.inspecciones.origen
            inspeccionesTable[0].lista = []
            inspeccionesTable[1].lista = []
            inspeccionesTable[2].lista = [
                {"Ejecutando por operador": numerosEnReportes.inspecciones["Ejecutando por operador"]},
                {"Reasignar": numerosEnReportes.inspecciones["Reasignar"]},
                {"Revisión Supervisor": numerosEnReportes.inspecciones["Revisión Supervisor"]},
                {"Revisión J. Maquinaria": numerosEnReportes.inspecciones["Revisión J. Maquinaria"]}    
            ]
            inspeccionesTable[3].lista = []
            inspeccionesTable[4].lista = []
            inspeccionesTable.forEach((item) => {
                item.open = false
            })

            setInspecciones(inspeccionesTable)
            const mantencionesTable = [...Mantenciones]
            mantencionesTable[0].number = numerosEnReportes.mantenciones.completadas
            mantencionesTable[1].number = numerosEnReportes.mantenciones.porCerrar
            mantencionesTable[2].number = numerosEnReportes.mantenciones.enProceso
            mantencionesTable[3].number = numerosEnReportes.mantenciones.asignar
            mantencionesTable[4].number = numerosEnReportes.mantenciones.origen
            mantencionesTable[0].lista = []
            mantencionesTable[1].lista = []
            mantencionesTable[2].lista = [
                {"Ejecutando por operador": numerosEnReportes.mantenciones["Ejecutando por operador"]},
                {"Reasignar": numerosEnReportes.mantenciones["Reasignar"]},
                {"Revisión Supervisor": numerosEnReportes.mantenciones["Revisión Supervisor"]},
                {"Revisión J. Maquinaria": numerosEnReportes.mantenciones["Revisión J. Maquinaria"]}    
            ]
            mantencionesTable[3].lista = []
            mantencionesTable[4].lista = []
            mantencionesTable.forEach((item) => {
                item.open = false
            })

            setMantenciones(mantencionesTable)

        } else {
            const inspeccionesTable = [...Inspecciones]
            inspeccionesTable[0].number = 0
            inspeccionesTable[1].number = 0
            inspeccionesTable[2].number = 0
            inspeccionesTable[3].number = 0
            inspeccionesTable[4].number = 0
            inspeccionesTable[0].lista = []
            inspeccionesTable[1].lista = []
            inspeccionesTable[2].lista = []
            inspeccionesTable[3].lista = []
            inspeccionesTable[4].lista = []
            setInspecciones(inspeccionesTable)
            const mantencionesTable = [...Mantenciones]
            mantencionesTable[0].number = 0
            mantencionesTable[1].number = 0
            mantencionesTable[2].number = 0
            mantencionesTable[3].number = 0
            mantencionesTable[4].number = 0
            mantencionesTable[0].lista = []
            mantencionesTable[1].lista = []
            mantencionesTable[2].lista = []
            mantencionesTable[3].lista = []
            mantencionesTable[4].lista = []
            setMantenciones(mantencionesTable)

        }
    },[numerosEnReportes])

    useEffect(() => {
        const numeroInicialPaginaTemp = (reportesPorPagina * pagina) - (reportesPorPagina) + 1
        setNumeroInicialPagina(numeroInicialPaginaTemp)
    }, [pagina, reportesPorPagina])

    useEffect(() => {
        console.log(numeroInicialPagina && tipoReporteSeleccionado.length > 0 && reportesPorPagina)
        if (numeroInicialPagina && tipoReporteSeleccionado.length > 0 && reportesPorPagina) {
            if (filtrarReporte) {
                buscarReportesFiltrados()
            } else {
                reportesSeleccionados(tipoReporteSeleccionado, numeroInicialPagina, reportesPorPagina, tipo)
                const totalPaginasTemp = Math.ceil(totalItems / reportesPorPagina)
                setTotalPaginas(totalPaginasTemp)
            }
        }
    }, [numeroInicialPagina, tipoReporteSeleccionado, reportesPorPagina, totalItems, filtrarReporte])

    useEffect(() => {
        if (filtrarReporte && valueFilter.length === 0) {
            setFiltrarReporte(false)
        }
    }, [valueFilter])

    const buscarReportesFiltrados = async () => {
        if (tipoReporteSeleccionado, reportesPorPagina, tipo) {
            if (valueFilter.length > 0) {
                const {numeroItems} = await filtroReporte(tipoReporteSeleccionado, numeroInicialPagina, reportesPorPagina, tipo, valueFilter)
                if (numeroItems !== null) {
                    const totalPaginasTemp = Math.ceil(numeroItems / reportesPorPagina)
                    setTotalPaginas(totalPaginasTemp)
                }
            } else {
                alert('Debe agregar un dato en el filtro para buscar')
            }
        }
    }

    const selectList = (item, idButton, tipoCache, index) => {
        setFiltrarReporte(false)
        setValueFilter('')
        setPagina(1)
        setNumeroInicialPagina(1)
        setVista(false)
        setTipoReporteSeleccionado(item.name)
        setTotalItems(item.number)
        setTipo(tipoCache)
        localStorage.setItem('buttonSelected', idButton)
        const inspeccionesCache = [...inspecciones]
        inspeccionesCache.forEach((el, i) => {
            if (tipoCache === 'Inspección') {
                if (el.name === item.name) {
                    el.open = !item.open
                }
            }
            const buttonCache = document.getElementById(`button_${i}_inspecciones`)
            if (buttonCache) {
                buttonCache.style.backgroundColor = el.buttonColor
            }
        })
        const mantencionesCache = [...mantenciones]
        mantencionesCache.forEach((el, i) => {
            if (tipoCache === 'Mantención') {
                if (el.name === item.name) {
                    el.open = !item.open
                }
            }
            const buttonCache = document.getElementById(`button_${i}_mantenciones`)
            if (buttonCache) {
                buttonCache.style.backgroundColor = el.buttonColor
            }
        })
        setInspecciones(inspeccionesCache)
        setMantenciones(mantencionesCache)
        document.getElementById(idButton).style.backgroundColor = '#ccc'
    }

    const compareNumbers = (a, b) => {
        return b.idIndex - a.idIndex;
    }

    useEffect(() => {
        console.log(allItemsSelected)
        if (allItemsSelected) {
            setListToShow([])
            const list = [...listToShowCache].map((item) => {
                return {...item, checked: true}
            })
            console.log(list)
            setListToShow(list)
            setListToShowCache(list)
        } else {
            setListToShow([])
            const list = [...listToShowCache].map((item) => {
                return {...item, checked: false}
            })
            setListToShow(list)
            setListToShowCache(list)   
        }
    }, [allItemsSelected])

    const selectAllItems = (e) => {
        console.log(e.target.checked)
        const list = listToShowCache.map((item) => {
            return {...item, checked: e.target.checked}
        })
        console.log(list)
    }

    const selectItem = (e, index) => {
        const list = [...listToShowCache]
        list[index].checked = e.target.checked
        setListToShow(list)
        setListToShowCache(list)
    }

    const deleteSelected = async () => {
        const listaSeleccionados = listToShowCache.filter(item => {return item.checked})
        if (listaSeleccionados.length === 0) {
            alert('Debe seleccionar al menos un reporte.')
        } else {
            if (confirm(`Se eliminarán ${listaSeleccionados.length} reportes. Confirme para continuar.`)) {
                await borrarListaReportes(listaSeleccionados.map(el => {
                    return el._id
                }))
                setListToShow(listToShowCache.filter(item => {return !item.checked}))
                setListToShowCache(listToShowCache.filter(item => {return !item.checked}))
                setPagina(0)
                setAllItemsSelected(false)
            }
        }

    }


    const textoFiltrado = (value) => {
        console.log(value)
        const listaCache = [...listSelectedCache]
        if (value.length > 0) {
            const lista = []
            listaCache.forEach((element, index) => {
                if (index === 0) {
                    console.log(element)
                }
                if (element.idIndex.toString().match((Number(value)).toString()) /* === Number(value) */ || 
                    element.maquina.model.match(value)/*  === value */ || 
                    element.maquina.equ.match(value) /* === value */ || 
                    element.sapId.match(value)/*  === value */) {
                        if (site === 'nada') {
                            lista.push(element)
                        } else {
                            if (admin && (element.idobra === site)) {
                                lista.push(element)
                            }
                        }
                }
                if (index === (listaCache.length - 1)) {
                    setListSelected(lista.sort(compareNumbers))
                    setTotalItems(lista.length)
                }
            })
        } else {
            setListSelected(listSelectedCache.sort(compareNumbers))
            setTotalItems(listSelected.length)
        }
    }

    useEffect(() => {
        const listaCache = [...listSelectedCache]
        if (site === 'nada') {
            setListSelected(listSelectedCache)
            setTotalItems(listSelectedCache.length)
        } else {
            const lista = []
            listaCache.forEach((element, index) => {
                if (element.site === site) {
                    lista.push(element)
                }
                if (index === (listaCache.length - 1)) {
                    setListSelected(lista)
                    setTotalItems(lista.length)
                }
            })
        }
    }, [site])

    const downloadFile = async () => {
        downloadReports2()
    }

    const downloadReports2 = async () => {
        const dataCompilada = await descargarDataCompilada()
        console.log(dataCompilada)
        if (dataCompilada.state) {
            const workbook = XLSX.utils.book_new();
            const worksheet = XLSX.utils.json_to_sheet(dataCompilada.data["Datos de reporte"]);
            const datosMaterial = XLSX.utils.json_to_sheet(dataCompilada.data["Datos de material"]);
            const datosTareasPendientes = XLSX.utils.json_to_sheet(dataCompilada.data["Datos de tareas pendientes"]);
            XLSX.utils.book_append_sheet(workbook, worksheet, "Datos de reporte");
            XLSX.utils.book_append_sheet(workbook, datosMaterial, "Datos de material");
            XLSX.utils.book_append_sheet(workbook, datosTareasPendientes, "Datos de tareas pendientes");
            XLSX.writeFile(workbook, `${Date.now()}.xlsx`)
        } else {
            alert('Datos no pueden ser descargados en este momento.')
        }
    }

    const openToReportSumary = () => {
        setOpenReportSumary(true)
    }

    const closeToReportSumary = () => {
        setOpenReportSumary(false)
    }

    const closeSeleccionarObra = () => {
        setOpnSeleccionarObra(false)
    }

    const closeKPI = () => {
        setOpnKPI(false)
    }
    const levelToState = (level, usersAssigned, state) => {
        if (level === 0 || !level) {
            if (usersAssigned.length > 0) {
                if (state==='En proceso') {
                    return 'Ejecutando por operador'
                } else if (state==='Asignar') {
                    return 'Reasignar'
                }
            } else {
                return 'Asignar'
            }
        } else if (level===1) {
            return 'Revisión Supervisor'
        } else if (level===2) {
            return 'Revisión J. Maquinaria'
        } else if (level===3) {
            return 'Revisión SAP'
        } else if (level===4) {
            return 'Terminado'
        }
    }

    const filterState = (key, type, item, number) => {
        let reportType
        if (type === 'inspeccion') {
            reportType = 'Inspección'
        }

        if (type === 'mantencion') {
            reportType = 'Mantención'
        }
        setPagina(1)
        setNumeroInicialPagina(1)
        setVista(false)
        setTipoReporteSeleccionado(key)
        setTotalItems(number)
    }

    return (
        <Box height='100%'>
        {openSeleccionarObra && <SeleccionarObraDialog open={openSeleccionarObra} handleClose={closeSeleccionarObra} />}
        {opnKPI && <ReportsKPIDialog 
            open={opnKPI}
            handleClose={closeKPI}
            reports={reports}
        />
        }
        {openReportSumary && <ReportsResumeDialog
            open={openReportSumary}
            handleClose={closeToReportSumary}
            reports={reports}
        />}
        <Grid className={'root'} container spacing={0}>
            <Grid className={'pageContainer'} item xs={12}>
                <Card elevation={0} className={'pageCard'} >
                    <Grid container alignItems='center' justifyContent='center'>
                        <div className='navigation-bar'>
                            <div style={{width: '100%', textAlign: 'left', color: '#333', backgroundColor: '#fff', borderRadius: 20}}>
                            <Toolbar style={{paddingLeft: 0, backgroundColor: '#F9F9F9', borderRadius: 10}}>
                                <IconButton onClick={() => setTimeout(() => {
                                    navigate(-1)
                                }, 500)}>
                                    <ArrowBackIos style={{color: '#333', fontSize: 16}}/> 
                                </IconButton>
                                <Typography variant='h1' style={{marginTop: 0, marginBottom: 0, fontSize: 16, width: '100%'}}>
                                    Ordenes de trabajo
                                </Typography>
                                {
                                    (admin && siteObject) && <p style={{ position: 'absolute',right: 250, textAlign: 'right', maxWidth: 300, borderColor: '#ccc' }}>
                                        Obra seleccionada: <br />{`${siteObject && siteObject.descripcion}`}
                                    </p>
                                }
                                {
                                    (admin | isSapExecutive | isShiftManager) &&
                                    <div style={{position: 'absolute', right: 320, width: 150}}>
                                        <div style={{position: 'relative', width: '100%'}}>
                                            <input
                                                style={{height: 24, width: '100%'}}
                                                value={valueFilter}
                                                onChange={(e) => {setValueFilter(e.target.value)}}
                                                placeholder='Filtrar Reporte'
                                                type="search" />
                                                <button
                                                    onClick={() => {
                                                        if (filtrarReporte) {
                                                            buscarReportesFiltrados()
                                                        } else {
                                                            setFiltrarReporte(true)
                                                        }}}
                                                    style={{
                                                        position: 'absolute',
                                                        padding: 0,
                                                        right: 0,
                                                        bottom: 0,
                                                        width: 24,
                                                        height: 24}}>
                                                    <Search style={{width: 21, height: 21}} />
                                                </button>
                                            {

                                                (valueFilter.length > 0)
                                                ?
                                                <button
                                                    onClick={() => {
                                                        /* if (filtrarReporte) {
                                                            setFiltrarReporte(false)
                                                        } */
                                                        setValueFilter('')
                                                    }}
                                                    style={{
                                                        position: 'absolute',
                                                        padding: 0,
                                                        right: 25,
                                                        bottom: 0,
                                                        width: 24,
                                                        height: 24,
                                                        border: 'transparent',
                                                        backgroundColor: 'transparent'}}>
                                                    <Close style={{width: 21, height: 21, color: '#ccc'}} />
                                                </button>
                                                :
                                                null
                                            }
                                        </div>
                                    </div>
                                }
                                {admin && <IconButton
                                    color={'primary'} 
                                    style={{ marginRight: 5 }}
                                    edge={'end'}
                                    hidden={!hableCreateReport}
                                    onClick={() => setOpnSeleccionarObra(true)}
                                    title='Seleccionar Obra'
                                >
                                    <FontAwesomeIcon icon={faMapMarker}/>
                                </IconButton>}
                                {admin && <IconButton
                                    color={'primary'} 
                                    style={{ marginRight: 5 }}
                                    edge={'end'}
                                    hidden={!hableCreateReport}
                                    onClick={() => deleteSelected()}
                                    title='Borrar OT Seleccionada'
                                >
                                    <FontAwesomeIcon icon={faTrash}/>
                                </IconButton>}
                                {admin && <IconButton
                                    color={'primary'} 
                                    style={{ marginRight: 5 }}
                                    edge={'end'}
                                    hidden={!hableCreateReport}
                                    onClick={() => setOpnKPI(true)}
                                    title='KPI'
                                >
                                    <FontAwesomeIcon icon={faChartBar}/>
                                </IconButton>}
                                {admin && <IconButton
                                    color={'primary'} 
                                    style={{ marginRight: 5 }}
                                    edge={'end'}
                                    hidden={!hableCreateReport}
                                    onClick={downloadFile}
                                    title='Exportar Información de Reportes'
                                >
                                    <FontAwesomeIcon icon={faDownload}/>
                                </IconButton>}
                                <IconButton
                                    color={'primary'} 
                                    style={{ marginRight: 5 }}
                                    edge={'end'}
                                    hidden={!hableCreateReport}
                                    onClick={openToReportSumary}
                                    title='Información de Reportes'
                                >
                                    <FontAwesomeIcon icon={faInfoCircle}/>
                                </IconButton>
                                <IconButton
                                    color={'primary'} 
                                    style={{ marginRight: 5 }}
                                    edge={'end'}
                                    onClick={()=>{navigate('/calendar')}} 
                                    title='Calendario'
                                >
                                    <FontAwesomeIcon icon={faCalendar}/>
                                </IconButton>
                                <IconButton
                                    style={{position: 'relative'}}
                                    color={'primary'} 
                                    edge={'end'}
                                    hidden={!hableCreateReport}
                                    onClick={()=>{navigate('/reports/create-report')}} 
                                    title='Nuevo reporte'
                                >
                                    <FontAwesomeIcon style={{position: 'absolute', zIndex: 100, fontSize: 12, top: 10, right: 10, color: '#F78E63', fontWeight: 600}} icon={faPlus}/>
                                    <FontAwesomeIcon style={{zIndex: 10}} icon={faClipboardList}/>
                                </IconButton>
                            </Toolbar>

                            </div>
                        </div>
                    </Grid>
                    <Grid container>
                        <Grid item xs={12} sm={12} md={4} lg={'auto'} xl={'auto'}>
                            <div className='menu-card'>
                                <h3>Inspecciones</h3>
                                <div style={{paddingLeft: 10, paddingRight: 10}}>
                                <List dense={true}>
                                {
                                    inspecciones.map((e, i) => {
                                        return(
                                            <div key={i}>
                                                <ListItemButton
                                                    style={{cursor: 'pointer', display: ((e.name === 'Origen') && isShiftManager) ? 'none' : 'flex', fontSize: 10, borderRadius: 10}}
                                                    key={`button_${i}_inspecciones`}
                                                    id={`button_${i}_inspecciones`} 
                                                    onClick={()=>
                                                        selectList(e, `button_${i}_inspecciones`, 'Inspección', i)}>
                                                    <FontAwesomeIcon icon={faCircle} size='2x' style={{marginRight: 10}} color={e.color}/>
                                                    <ListItemText primary={e.name} />
                                                    <Chip label={e.number} size={'small'} style={{width: 50}} />
                                                    {
                                                        e.lista && Object.values(e.lista).length > 0
                                                        ?
                                                        (e.open ? <ExpandLess style={{marginRight: 10, marginLeft: 10}} /> : <ExpandMore style={{marginRight: 10, marginLeft: 10}} />)
                                                        :
                                                        <div style={{height: 24, width: 24, margin: '0px 10px'}}>

                                                        </div>
                                                    }
                                                </ListItemButton>
                                                <Collapse in={e.open} timeout="auto" unmountOnExit>
                                                    <List dense={true} style={{marginLeft: 10}} component="div" disablePadding>
                                                        {
                                                            e.lista && Object.values(e.lista).map((object, n) => {
                                                                const [key, value] = Object.entries(object)[0]
                                                                return (
                                                                    <ListItemButton key={n} onClick={() => {filterState(key, 'inspeccion', e, value)}} style={{cursor: 'pointer', borderRadius: 10 }} sx={{ pl: 4}}>
                                                                        <ListItemText primary={key} />
                                                                        <Chip label={value} size={'small'} style={{width: 40, fontSize: 11}} />
                                                                    </ListItemButton> 
                                                                )
                                                            })
                                                        }
                                                    </List>
                                                </Collapse>
                                            </div>
                                        )
                                    })
                                }
                                </List>
                                </div>
                            </div>
                            <div className='menu-card'>
                                <h3>Mantenciones</h3>
                                <div style={{paddingLeft: 0, paddingRight: 10}}>
                                <List dense={true}>
                                {
                                    mantenciones.map((e, i) => {
                                        return(
                                            <div key={i}>
                                                <ListItemButton 
                                                    style={{cursor: 'pointer', display: ((e.name === 'Origen') && isShiftManager) ? 'none' : 'flex', fontSize: 10, borderRadius: 10}}
                                                    key={`button_${i}_mantenciones`} 
                                                    id={`button_${i}_mantenciones`} 
                                                    onClick={()=>{
                                                        selectList(e, `button_${i}_mantenciones`, 'Mantención', i)
                                                    }}
                                                    >
                                                    <FontAwesomeIcon icon={faCircle} size='2x' style={{marginRight: 10}} color={e.color}/>
                                                    <ListItemText primary={e.name} />
                                                    <Chip label={e.number} size={'small'} style={{width: 50}} />
                                                    {
                                                        e.lista && Object.values(e.lista).length > 0
                                                        ?
                                                        (e.open ? <ExpandLess style={{marginRight: 10, marginLeft: 10}} /> : <ExpandMore style={{marginRight: 10, marginLeft: 10}} />)
                                                        :
                                                        <div style={{height: 24, width: 24, margin: '0px 10px'}}>

                                                        </div>
                                                    }
                                                </ListItemButton>
                                                <Collapse in={e.open} timeout="auto" unmountOnExit>
                                                    <List dense={true} style={{marginLeft: 10}} component="div" disablePadding>
                                                        {
                                                            e.lista && Object.values(e.lista).map((object, n) => {
                                                                const [key, value] = Object.entries(object)[0]
                                                                return (
                                                                    <ListItemButton key={n} onClick={() => {filterState(key, 'mantencion', e, value)}} style={{cursor: 'pointer', borderRadius: 10 }} sx={{ pl: 4}}>
                                                                        <ListItemText primary={key} />
                                                                        <Chip label={value} size={'small'} style={{width: 40, fontSize: 11}} />
                                                                    </ListItemButton> 
                                                                )
                                                            })
                                                        }
                                                    </List>
                                                </Collapse>
                                            </div>
                                        )
                                    })
                                }
                                </List>
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={8} lg={'auto'} xl={'auto'}>
                            {
                                vista ? <div>
                                    <img style={{margin: 0, position: 'absolute', top: '50%', left: 'calc(100%/1.53)', msTransform: 'translateY(-50%)', transform: 'translateY(-50%)'}} src="../../assets/icons/Arrow.svg" alt="" />
                                    <div style={{textAlign: 'center', position: 'absolute', top: '55%', left: 'calc(100%/1.6)'}}>
                                        <p>Selecciona una opción <br /> para ver el detalle</p>
                                    </div>
                                </div>
                                :
                                <div style={{height: 'calc(100vh - 250px)', width:'calc(100vw - 350px)', display: 'block', overflowY: 'auto'}}>
                                    <ReportsList 
                                        list={listToShow}
                                        typeReportsSelected={tipoReporteSeleccionado}
                                        statusReports={statusReports}
                                        getReports={getReports}
                                        tipo={tipo}
                                        setAllItemsSelected={setAllItemsSelected}
                                        allItemsSelected={allItemsSelected}
                                        selectItem={selectItem}
                                    />
                                    {
                                        (listToShow && listToShow.length > 0) && 
                                        <Pagination 
                                            color={'primary'}
                                            style={{ position: 'absolute', right: 20, bottom: 10 }}
                                            count={totalPaginas}
                                            defaultPage={pagina}
                                            page={pagina}
                                            onChange={(e, page) => {setPagina(page)}}
                                        />
                                    }
                                </div>
                            }
                        </Grid>
                    </Grid>
                </Card>
            </Grid>
        </Grid>
    </Box>

    )
}

export default ReportsPage