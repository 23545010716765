import axios from 'axios'

export const guardarSesion = async (data) => {
    const response = await axios.post('/sesiones/guardarSesion', data)
    return response.data
}

export const listaDispositivos = async () => {
    const response = await axios.get('/sesiones/listaDispositivos')
    return response.data
}