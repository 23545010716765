import React, { createContext, useContext, useEffect, useState } from 'react'
import { dictionaryList, languageOptions } from '../language'
import { listaDispositivos } from '../routes/sesiones.routes'

const DispositivosContext = createContext()

export const DispositivosProvider = (props) => {
    const [dispositivos, setDispositivos] = useState([])

    useEffect(() => {
        obetenerListaDipositivos()
    }, [])

    useEffect(() => {
        console.log(dispositivos)
    }, [dispositivos])

    const obetenerListaDipositivos = async () => {
        const response = await listaDispositivos()
        setDispositivos(response.lista)
    }

    const provider = {
        dispositivos
    }

    return (
        <DispositivosContext.Provider value={provider} {...props} />
    )
}

export const useDispositivosContext = () => useContext(DispositivosContext)