import Modal from '@mui/material/Modal';
import Webcam from "react-webcam";
import Box from '@mui/material/Box';
import { useEffect, useRef, useState } from "react";
import { Button, IconButton } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faBolt, faCamera, faCircleNotch, faSync } from '@fortawesome/free-solid-svg-icons';

const CameraModal = (props) => {
  const { open, handleClose, onImageCapture } = props;
  const webcamRef = useRef(null);
  const [imageSrc, setImageSrc] = useState(null);
  const [facingMode, setFacingMode] = useState("environment");
  const [flashMode, setFlashMode] = useState(false);
  const [torchOn, setTorchOn] = useState(false);

  const videoConstraints = {
    width: 5000,
    height: 5000,
    facingMode,
  };
  

  const setTorchMode = async (enabled) => {
    if (!webcamRef.current?.stream) return;

    const track = webcamRef.current.stream.getVideoTracks()[0];

    try {
      await track.applyConstraints({
        advanced: [{ 
          torch: enabled,
          exposureMode: 'manual',
          exposureCompensation: enabled ? -2 : 0
        }]
      });
    } catch (error) {
      console.log('Error configuring flash:', error);
    }
  };

  const toggleTorch = async () => {
    const newTorchState = !torchOn;
    setTorchOn(newTorchState);
    await setTorchMode(newTorchState);
  };

  const handleCapture = async () => {
    const videoElement = webcamRef.current?.video;
    const canvas = document.createElement('canvas');
  
    if (videoElement) {
      try {
        if (torchOn) {
          await setTorchMode(true);
        }
  
        const width = videoElement.videoWidth;
        const height = videoElement.videoHeight;
        canvas.width = width;
        canvas.height = height;
  
        const context = canvas.getContext('2d');
        context.drawImage(videoElement, 0, 0, width, height);
  
        const imageSrc = canvas.toDataURL('image/jpeg', 0.8);
        setImageSrc(imageSrc);
  
        if (torchOn) {
          await new Promise((resolve) => setTimeout(resolve, 2000));
          await setTorchMode(false);
        }
      } catch (err) {
        console.log('Capture error:', err);
        if (torchOn) {
          await setTorchMode(false);
        }
      }
    }
  };
  

  // limpiar el modal al cerrar
  useEffect(() => {
    if (!open && flashMode) {
      setTorchMode(false);
    }
  }, [open]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '100vw',
          height: '100vh',
          bgcolor: 'black'
        }}
        onClick={(e) => e.stopPropagation()}
      >
        <Box sx={{
          position: 'relative',
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}>
          {imageSrc ? (
            <img
              src={imageSrc}
              alt="captured"
              style={{
                height: '100%',
                width: '100%',
                objectFit: 'contain'
              }}
            />
          ) : (
            <Webcam
              ref={webcamRef}
              audio={false}
              screenshotFormat="image/jpeg"
              videoConstraints={{
                ...videoConstraints,
                facingMode,
              }}
              style={{
                height: '100vh',
                width: '100vw',
                objectFit: 'contain'
              }}
            />
          )}

          {!imageSrc && (
            <>
              <IconButton
                style={{
                  position: "absolute",
                  right: "1.5vw",
                  top: "10vh",
                  fontSize: "4.4rem",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  paddingRight: '1.5vw'
                }}
                onClick={toggleTorch}
              >
                <FontAwesomeIcon
                  icon={faBolt}
                  color={torchOn ? "#ffeb3b" : "#ffffff"}
                />
              </IconButton>

              <IconButton
                style={{
                  position: "absolute",
                  right: "1.5vw",
                  bottom: "50%",
                  fontSize: "4.4rem",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                onClick={handleCapture}
              >
                <span style={{ position: "relative" }}>
                  <FontAwesomeIcon
                    icon={faCircleNotch}
                    color="#920000"
                    style={{
                      fontSize: "4.4rem",
                    }}
                  />
                  <FontAwesomeIcon
                    icon={faCamera}
                    color="white"
                    style={{
                      fontSize: "1.8rem",
                      position: "absolute",
                      top: "45%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  />
                </span>
              </IconButton>
            </>
          )}

          <IconButton
            style={{
              position: 'absolute',
              left: '1.5vw',
              top: '10vh',
              fontSize: 'clamp(3rem, 3vw, 3.4rem)',
              display: 'flex',
              alignItems: 'center',
            }}
            onClick={handleClose}
          >
            <FontAwesomeIcon
              icon={faArrowLeft}
              color='#920000'
            />
          </IconButton>

          {imageSrc && (
            <>
              <FontAwesomeIcon
                icon={faSync}
                color='#920000'
                style={{
                  cursor: 'pointer',
                  position: 'absolute',
                  left: '1.5vw',
                  bottom: '50%',
                  fontSize: 50,
                  padding: '8px',
                  fontSize: 'clamp(3.5rem, 3vw, 3.4rem)',
                }}
                onClick={() => setImageSrc(null)}
              />
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#920000",
                  color: "#ffffff",
                  "&:hover": {
                    backgroundColor: "#b00000",
                  },
                  fontWeight: "bold",
                  fontSize: "1.3rem",
                  textTransform: "none",
                  borderRadius: "8px",
                  padding: "8px 16px",
                  position: 'absolute',
                  bottom: 55,
                  "@media (max-width: 1120px)": {
                    bottom: 65,
                  },
                  "@media (max-width: 1090px)": {
                    bottom: 70,
                  },
                }}
                onClick={() => {
                  onImageCapture(imageSrc);
                  handleClose();
                }}
              >
                Guardar Imagen
              </Button>
            </>
          )}
        </Box>
      </Box>
    </Modal>
  );
};

export default CameraModal;