import { useEffect } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { useAuth } from "../../context"

export const Device = () => {
    const {setDispositivoIdData} = useAuth()
    const params = useParams()
    const navigate = useNavigate()

    useEffect(() => {
        console.log(params)
        if (params && params.deviceId) {
            setDispositivoIdData(params.deviceId)
            localStorage.setItem('deviceId', params.deviceId)
            navigate('/', {replace: true})
        }
    }, [params])
    return (
        <div>

        </div>
    )
}