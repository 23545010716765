import { useState, useEffect, useContext, forwardRef } from 'react';
import { 
    Toolbar,
    Fab,
    Grid,
    Dialog,
    Slide,
    Skeleton,

} from '@mui/material';
import { Close } from '@mui/icons-material';
import { getUserNameById, styleModalReport } from '../config';
import { reportsRoutes, usersRoutes } from '../routes';
import { dateWithTime } from '../config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleLeft, faArrowUp, faPen, faTrash } from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from 'react-router-dom';
import { AuthContext, useExecutionReportContext, useReportsContext } from '../context';
import { reportsDatabase } from '../indexedDB';
import { dateV2 } from '../config/date_v2';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});

const ReviewReportDialog = ({open, report, onlyClose}) => {
    const {admin, isSapExecutive} = useContext(AuthContext)
    const {setReport} = useExecutionReportContext()
    const {getReports, getReportsOffline} = useReportsContext()
    const [ colorState, setColorState ] = useState();
    const { idIndex, guide, state, siteName, usersAssigned, sapId, maquina, description, obra, historialAsignaciones } = report;
    const [ userAssignedName, setUserAssignedName ] = useState()
    const [ userShiftManagerName, setUserShiftManagerName ] = useState()
    const [ userChiefMachineryName, setChiefMachineryName ] = useState()
    const [ userSapExecutiveName, setUserSapExecutiveName ] = useState()
    const [ loadingDelete, setLoadingDelete ] = useState(false)
    const [ toEditSapId, setToEditSapId ] = useState(false)
    const [ sapIdToEdit, setSapIdToEdit ] = useState(sapId)
    const [tiempoDesarrollo, setTiempoDesarrollo] = useState('')
    const [tiempoEnProcesoOperador, setTiempoEnProcesoOperador] = useState(0)
    const [tiempoAprobacionSup, setTiempoAprobacionSup] = useState(0)
    const [tiempoJMaquinaria, setTiempoJMaquinaria] = useState(0)
    const [tiempoCierreOt, setTiempoCierreOt] = useState(0)
    const [tiempoTotal, setTiempoTotal] = useState(0)
    const [promedioOperadores, setPromedioOperadores] = useState(0)
    const [historialConOperadores, setHistorialConOperadores] = useState([])
    const [mostrarHistorial, setMostrarHistorial] = useState(false)
    const [mostrarHistorialAsignaciones, setMostrarHistorialAsignaciones] = useState(false)
    const [historialAsignacionesCache, setHistorialAsignacionesCache] = useState([])

    const [totalBacklogs, setTotalBacklogs] = useState()
    const navigate = useNavigate()

    const getUsers = async () => {
        let usersString = []
        for (let i = 0; i < usersAssigned.length; i++) {
            const user = await usersRoutes.getUser(usersAssigned[i])
            if (user && user.data) {
                usersString.push(user.data.name + ' ' + user.data.lastName)
            }
        }
        setUserAssignedName(usersString)
    }

    useEffect(() => {
        if (historialAsignaciones && historialAsignaciones.length > 0) {
            console.log(historialAsignaciones)
            const historialTemps = []
            historialAsignaciones.sort((a, b) => {
                if (a.id > b.id) {
                    return -1
                }
                if (a.id < b.id) {
                    return 1
                }
                return 0
            }).forEach(async (h, i) => {
                const asignadoPor = await getUserNameById(h.asignadoPor)
                const operario = await getUserNameById(h.operario)
                const fecha = dateV2(h.id, 'full')
                historialTemps.push({
                    asignadoPor,
                    operario,
                    fecha
                })
                if (i === (historialAsignaciones.length - 1)) {
                    setHistorialAsignacionesCache(historialTemps)
                }
            })
        }
    },[historialAsignaciones])

    useEffect(() => {
        console.log(historialAsignacionesCache)
    }, [historialAsignacionesCache])

    useEffect(() => {
        init()
        if (report) {
            getBacklogs()
            console.log(report)
            if (report.ejecuciones && report.ejecuciones.length > 0) {
                let tiempoEfectivo = 0
                report.ejecuciones.forEach((ejecucion) => {
                    const inicio = Date.parse(ejecucion.inicio)
                    const termino = Date.parse(ejecucion.termino)
                    console.log(`${termino} - ${inicio} = ${termino - inicio}`)
                    tiempoEfectivo = tiempoEfectivo + (termino - inicio)
                })
                setTiempoDesarrollo(secondsToString(tiempoEfectivo * 0.001))
            }
            setTiempoEnProcesoOperador(Number((((((Date.parse(report.endReport) - Date.parse(report.dateInit)) / 1000) / 60) / 60) / 24).toFixed(2)))
            setTiempoAprobacionSup(Number((((((Date.parse(report.shiftManagerApprovedDate) - Date.parse(report.endReport)) / 1000) / 60) / 60) / 24).toFixed(2)))
            setTiempoJMaquinaria(Number((((((Date.parse(report.chiefMachineryApprovedDate) - Date.parse(report.shiftManagerApprovedDate)) / 1000) / 60) / 60) / 24).toFixed(2)))
            setTiempoCierreOt(Number((((((Date.parse(report.dateClose) - Date.parse(report.chiefMachineryApprovedDate)) / 1000) / 60) / 60) / 24).toFixed(2)))
            let totalActividadesConOperadores = 0
            let operadores = 0
            const historial = []
            if (report.history && report.history.length > 0) {
                report.history.forEach(async (item, i) => {
                    if (item.operadores) {
                        const usuario = await usersRoutes.getUser(item.userSendingData)
                        item.usuario = usuario.data
                        historial.push(item)
                        totalActividadesConOperadores = totalActividadesConOperadores + 1
                        operadores = operadores + Number(item.operadores)
                        console.log(operadores)
                    }
                    if (i === (report.history.length - 1)) {
                        const promedioOperadoresCache = operadores / historial.length
                        console.log(promedioOperadoresCache)
                        setPromedioOperadores(promedioOperadoresCache)
                        setHistorialConOperadores(historial)
                    }
                })
            }
        }
    }, [report])

    const secondsToString = (seconds) => {
        var hour = Math.floor(seconds / 3600);
        hour = (hour < 10)? '0' + hour : hour;
        var minute = Math.floor((seconds / 60) % 60);
        minute = (minute < 10)? '0' + minute : minute;
        var second = seconds % 60;
        second = (second < 10)? '0' + second : second;
        return hour + ':' + minute /* + ':' + second */;
    }

    const init = async () => {
        getUserNameById(report.chiefMachineryApprovedBy).then(user => {
            console.log(user)
            setChiefMachineryName(user)
        })
        getUserNameById(report.shiftManagerApprovedBy).then(user => {
            setUserShiftManagerName(user)
        })
        getUserNameById(report.sapExecutiveApprovedBy).then(user => {
            setUserSapExecutiveName(user)
        })
        getUsers();
        if(state === 'Asignar') {
            setColorState('#DE4343');
        }else if(state === 'En proceso') {
            setColorState('#F2994A');
        }else if(state === 'Por cerrar') {
            setColorState('#F2C94C');
        }else if(state === 'Completadas') {
            setColorState('#27AE60');
        }
    }

    const toReport = () => {
        setReport(report)
        navigate(`/assignment/${idIndex}`)
    }

    const removeDuplicates = (arr) => {
        const unique = []
        for(let i=0; i < arr.length; i++) {
            if (unique.indexOf(arr[i]) === -1) {
                unique.push(arr[i]);
            }
        }
        return unique;
    }
    

    const removeOt = async () => {
        if(confirm(`Se borrará la OT ${report.idIndex}. Su eliminación será permanente. ¿Desea continuar?`)) {
            setLoadingDelete(true)
            report.deleted = true
            const response = await reportsRoutes.editReportById(report)
            console.log(response.data)
            const {database} = await reportsDatabase.initDbReports()
            await reportsDatabase.eliminar(report.idIndex, database)
            getReportsOffline()
            getReports()
            onlyClose()
        }
    }

    const changeInputSapId = () => {
        if (!toEditSapId) {
            setToEditSapId(true)
        } else {
            setToEditSapId(false)
        }
    }

    const saveChangesSapId = async () => {
        if (sapIdToEdit.length < 1) {
            alert('Debe agregar algún número SAP')
        } else {
            try {
                const reportToEdit = {
                    _id: report._id,
                    sapId: sapIdToEdit
                }
                const response = await reportsRoutes.editReportById(reportToEdit)
                console.log(response)
                if (response) {
                    report.sapId = sapIdToEdit
                    changeInputSapId()
                }
            } catch (error) {
                
            }
        }
    }

    const getBacklogs = async () => {
        const response = await reportsRoutes.findBacklogsInReport(report._id)
        console.log(response.data.length)
        if (response && response.data) {
            setTotalBacklogs(Number(response.data.length))
        } else {
            setTotalBacklogs(0)
        }
    }
    
    return(
        <Dialog
            open={open}
            TransitionComponent={Transition}
            adaptiveHeight={true}
            maxWidth={'xl'}
            >
            <div style={{padding: 30, width: 1000}}>
                <Toolbar style={{width: '100%', height: 59, paddingLeft: 0}}>
                    <h2>{ (report.reportType === 'Mantención') ? `Pauta de ${report.reportType}` : ''} Equ: {maquina.equid.replace('00000000', '')} / {maquina.model}{/* SAP {sapId ? sapId : 'N/A'} */}</h2>
                    <div style={{ position: 'absolute', right: 65 , backgroundColor: colorState, paddingTop: 3, borderRadius: 5, width: 100, height: 20, textAlign: 'center'}}>
                        <p style={{margin: 0, fontSize: 12}}>{state.toUpperCase()}</p>
                    </div>
                </Toolbar>
                <Grid container>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                        <strong>Faena:</strong> <br />
                        {obra.descripcion}
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                        <strong>Número de orden:</strong> <br />
                        {idIndex}
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                            <strong>{report.reportType === 'Mantención' ? 'N° SAP:' : 'Aviso SAP:'}</strong> <br />
                        <Grid container>
                            <Grid item>
                            {
                                !toEditSapId ?
                                sapIdToEdit ? sapIdToEdit : 'N/A'
                                :
                                <input onChange={(e) => setSapIdToEdit(e.target.value)} value={sapIdToEdit} />
                            }
                            </Grid>
                            {(isSapExecutive || admin) && <Grid item>
                                {
                                    !(toEditSapId) ?
                                    <button style={{marginLeft: 10, cursor: 'pointer'}} onClick={changeInputSapId}>
                                        <FontAwesomeIcon icon={faPen} />
                                    </button> :
                                    <button style={{marginLeft: 10, cursor: 'pointer'}}>
                                        <FontAwesomeIcon icon={faArrowUp} onClick={saveChangesSapId}/>
                                    </button>
                                }
                            </Grid>}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                        <div style={{marginTop: 30, width: '100%'}}>
                            <strong>Pauta:</strong> <br />
                            {guide}
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>
                        <div style={{marginTop: 30, width: '100%'}}>
                            <strong>Descripción:</strong> <br />
                            {description ? description : 'Sin descripción'}
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={4}>

                        {(totalBacklogs || totalBacklogs === 0) ? <div style={{marginTop: 30, width: '100%'}}>
                            <p style={{margin: 0}}><strong>Backlogs: </strong>{totalBacklogs > 0 ? 'Si' : 'No'}</p>
                            {(totalBacklogs > 0) && <p style={{margin: 0}}><strong>Cantidad:</strong> {totalBacklogs}</p>}
                        </div>
                        :
                        <div style={{marginTop: 30, width: '100%'}}>
                            <Skeleton variant="text" sx={{ fontSize: 16, width: 120 }} />
                        </div>
                    }
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item md={6} xs={12} sm={12} lg={4} xl={4} >
                        <div style={{marginTop: 30, width: '100%'}} className='dataDialog'>
                            <p>Revisores: </p>
                            <p>Pauta asignada a:</p>
                            {
                                !userAssignedName
                                &&
                                <>
                                <Skeleton variant="text" sx={{ fontSize: 16, width: 120 }} />
                                <Skeleton variant="text" sx={{ fontSize: 16, width: 120 }} />
                                <Skeleton variant="text" sx={{ fontSize: 16, width: 120 }} />
                                </>
                            }
                            {
                                userAssignedName && userAssignedName.length > 0 ?
                                removeDuplicates(userAssignedName).map((user, i) => {
                                    return <p key={i}><b>{user}</b></p>
                                })
                                :
                                <p style={{marginTop: 0}}><b>No encontrado</b></p>
                            }
                            <p>Pauta enviada a revisión por: <br /><b>{!userAssignedName ? <Skeleton variant="text" sx={{ fontSize: 16, width: 120 }} /> : ((report.level && report.level > 0) && userAssignedName && userAssignedName[0]) ? userAssignedName[0] : 'No enviado'}</b></p>
                            <p>Jefe de turno: <br /> <b>{(!userShiftManagerName) ? <Skeleton variant="text" sx={{ fontSize: 16, width: 120 }} /> : userShiftManagerName}</b></p>
                            <p>Jefe de maquinaria: <br /> <b>{!userChiefMachineryName ? <Skeleton variant="text" sx={{ fontSize: 16, width: 120 }} /> : userChiefMachineryName}</b></p>
                            <p>Ejecutivo SAP: <br /> <b>{!userSapExecutiveName ? <Skeleton variant="text" sx={{ fontSize: 16, width: 120 }} /> : userSapExecutiveName}</b></p>
                        </div>
                    </Grid>
                    <Grid item lg={3} xl={3} >
                        <div style={{marginTop: 30, width: '100%'}} className='dataDialog'>
                            <p>Registro horario: </p>
                            <p>Fecha Creación OT: <br /> <b>{dateV2(report.createdAt, 'full')}</b></p>
                            <p>Fecha Prevista: <br /> <b>{(dateV2(report.datePrev, 'full', false) === 'Sin información') ? 'Faltan datos' : dateV2(report.datePrev, 'full', false)}</b></p>
                            <p>Fecha Inicio: <br /> <b>{(dateV2(report.dateInit, 'full') === 'Sin información') ? 'No inicado' : dateV2(report.dateInit, 'full')}</b></p>
                            <p>Fecha Término: <br /> <b>{(dateV2(report.endReport, 'full') === 'Sin información') ? 'No finalizado' : dateV2(report.endReport, 'full')}</b></p>
                            <p>Aprobado Jefe Turno: <br /> <b>{(dateV2(report.shiftManagerApprovedDate, 'full') === 'Sin información') ? 'No cerrado' : dateV2(report.shiftManagerApprovedDate, 'full')}</b></p>
                            <p>Aprobado Jefe maquinaria: <br /> <b>{(dateV2(report.chiefMachineryApprovedDate, 'full') === 'Sin información') ? 'No cerrado' : dateV2(report.chiefMachineryApprovedDate, 'full')}</b></p>
                            <p>Cerrado SAP: <br /> <b>{(dateV2(report.dateClose, 'full') === 'Sin información') ? 'No cerrado' : dateV2(report.dateClose, 'full')}</b></p>
                        </div>
                    </Grid>
                    <Grid item lg={5} xl={5} >
                        <div style={{marginTop: 30, width: '100%'}} className='dataDialog'>
                            {admin && <p>Tiempo de ejecución efectiva de pauta: <b>{tiempoDesarrollo ? tiempoDesarrollo : 0} hrs</b></p>}
                            <p>Tiempo ejecución de operadores: <b>{isNaN(tiempoEnProcesoOperador) ? 0 : tiempoEnProcesoOperador} días</b></p>
                            <p>Aprobación de supervisor: <b>{isNaN(tiempoAprobacionSup) ? 0 : tiempoAprobacionSup} días</b></p>
                            <p>Aprobación de jefe maquinaria: <b>{isNaN(tiempoJMaquinaria) ? 0 : tiempoJMaquinaria} días</b></p>
                            <p>Cierre de la OT: <b>{isNaN(tiempoCierreOt) ? 0 : tiempoCierreOt} días</b></p>
                            <p>Tiempo Total: <b>{isNaN(tiempoTotal) ? 0 : tiempoTotal} días</b></p>
                            <p>Promedio de operadores por responsable: <b>{isNaN(promedioOperadores) ? 0 : promedioOperadores}</b> <button className='buttonGeneric' onClick={() => {setMostrarHistorial(mostrarHistorial ? false : true)}}>{mostrarHistorial ? 'Ocultar' : 'Detalle'}</button></p>
                            {
                                mostrarHistorial &&
                                
                                <ul style={{margin: 0}}>
                                    {
                                        (historialConOperadores.length > 0) ? historialConOperadores.map((h, i) => {
                                            return <li key={i} style={{fontSize: 13}}>
                                                {`${h.usuario.name} ${h.usuario.lastName}: ${h.operadores} operadores mencionados.`}
                                            </li>
                                        })
                                        :
                                        <li style={{fontSize: 13}}>
                                            Sin datos
                                        </li>
                                    }
                                </ul>
                                
                            }
                            <div>
                            <p style={{margin:0}}>
                                    Historial de asignaciones <button style={{cursor: 'pointer'}} onClick={() => {setMostrarHistorialAsignaciones(mostrarHistorialAsignaciones ? false : true)}} >{mostrarHistorialAsignaciones ? 'Ocultar' : 'Detalle'}</button> </p>
                            </div>
                            <div style={{maxHeight: 150, overflowY: 'auto'}}>
                                
                                {
                                    mostrarHistorialAsignaciones && historialAsignacionesCache.map((h, i) => {
                                        return (
                                            <div style={{paddingLeft: 10, borderBottom: '1px #ccc solid'}} key={i}>
                                                <p style={{margin: 0}}>Asignado por: {h.asignadoPor}</p>
                                                <p style={{margin: 0}}>Operario: {h.operario}</p>
                                                <p style={{margin: 0}}>Fecha: {h.fecha}</p>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                        <div style={{padding: 10}}>
                            <button style={{ cursor: 'pointer', height: 50 , width: '100%', backgroundColor: 'green', color: '#fff', fontSize: 20 }} onClick={toReport}> <strong> <FontAwesomeIcon icon={faArrowCircleLeft} /> Revisar OT {idIndex} </strong> </button>
                        </div>
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                        {admin && <div style={{padding: 10}}>
                            <button style={{ cursor: 'pointer', height: 50 , width: '100%', backgroundColor: 'red', color: '#fff', fontSize: 20 }} onClick={()=>{removeOt()}}> <strong> <FontAwesomeIcon icon={faTrash} /> Borrar OT {idIndex} </strong> </button>
                        </div>}
                    </Grid>
                </Grid>
                <Fab onClick={onlyClose} style={{position: 'absolute', right: 10, top: 10, boxShadow: 'none', backgroundColor: 'transparent'}}>
                    <Close style={{color: '#ccc'}} />
                </Fab>
                
            </div>
        </Dialog>
    )
}

export default ReviewReportDialog